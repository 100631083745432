import { Button, DropdownMenu, Icon, IconButton } from '@guidde/design-system'
import { type Level } from '@tiptap/extension-heading'
import {
    faBold,
    faCaretDown,
    faLink,
    faUnderline,
    faImage,
    faItalic,
    faStrikethrough,
    faCode,
    faBlockQuote,
    faPageBreak,
    faH1,
    faH2,
    faH3,
    faH4,
    faH5,
    faH6,
    faListUl,
    faListOl
} from '@fortawesome/pro-regular-svg-icons'
import { type Editor } from '@tiptap/react'
import { useMemo } from 'react'
import { ToggleButton } from '@mui/material'

export type BasicActionButtonProps = {
    label: string
    action: () => void
    isActive: boolean
    icon: any
}

export type ActionButtonProps = {
    editor: Editor
}
export type BasicDropdownActionProps = {
    label: string
    action: (item: { value: string }) => void
    options: { label: string; value: string }[]
    activeLabel: string
}

const BasicDropdownAction = ({ label, action, options, activeLabel }: BasicDropdownActionProps) => {
    return (
        <DropdownMenu onMenuItemSelect={action} options={options}>
            <Button
                variant="text"
                color="primary"
                size="small"
                label={label}
                endIcon={<Icon icon={faCaretDown} size="xs" />}
            >
                {activeLabel}
            </Button>
        </DropdownMenu>
    )
}

const BasicToggleAction = ({ action, isActive, icon, label }: BasicActionButtonProps) => {
    return (
        <ToggleButton
            value={label}
            selected={isActive}
            onChange={action}
            aria-label={label}
            size="small"
        >
            <Icon icon={icon} size="xs" />
        </ToggleButton>
    )
}

const BasicAction = ({ action, isActive, icon, label }: BasicActionButtonProps) => {
    return (
        <IconButton
            onClick={action}
            variant={isActive ? 'outlined' : 'text'}
            label={label}
            color="primary"
            size="small"
        >
            <Icon icon={icon} size="xs" />
        </IconButton>
    )
}

export const ColorDropdown = ({ editor }: ActionButtonProps) => {
    const colors = useMemo(
        () => [
            { label: 'Default', value: 'default' },
            { label: 'Black', value: '#000000' },
            { label: 'White', value: '#FFFFFF' },
            { label: 'Red', value: '#FF0000' },
            { label: 'Green', value: '#00FF00' },
            { label: 'Blue', value: '#0000FF' }
        ],
        []
    )

    return BasicDropdownAction({
        label: 'Text Color',
        action: item => {
            editor
                .chain()
                .focus()
                .setColor(item.value === 'default' ? '' : item.value)
                .run()
        },
        options: colors,
        activeLabel: editor.getAttributes('textStyle').color || 'Default'
    })
}

export const FontFamilyDropdown = ({ editor }: ActionButtonProps) => {
    const fontFamilies = useMemo(
        () => [
            { label: 'Default', value: 'default' },
            { label: 'Arial', value: 'Arial' },
            { label: 'Helvetica', value: 'Helvetica' },
            { label: 'Times New Roman', value: 'Times New Roman' },
            { label: 'Courier New', value: 'Courier New' },
            { label: 'Georgia', value: 'Georgia' }
        ],
        []
    )

    return BasicDropdownAction({
        label: 'Font Family',
        action: item => {
            editor
                .chain()
                .focus()
                .setFontFamily(item.value === 'default' ? '' : item.value)
                .run()
        },
        options: fontFamilies,
        activeLabel: editor.isActive('textStyle', {
            fontFamily: editor.getAttributes('textStyle').fontFamily
        })
            ? editor.getAttributes('textStyle').fontFamily
            : 'Default'
    })
}

export const HeadingDropdown = ({ editor }: ActionButtonProps) => {
    const headings = useMemo(() => [1, 2, 3, 4, 5, 6] as Level[], [])

    return BasicDropdownAction({
        label: 'Pick heading',
        action: item => {
            editor
                .chain()
                .focus()
                .toggleHeading({ level: Number(item.value) as Level })
                .run()
        },
        options: headings.map(level => ({
            label: `Heading ${level}`,
            value: String(level)
        })),
        activeLabel: editor.isActive('heading')
            ? `Heading ${editor.getAttributes('heading').level}`
            : 'Pick heading'
    })
}

export const ToggleBold = ({ editor }: ActionButtonProps) =>
    BasicToggleAction({
        action: () => {
            editor.chain().focus().toggleBold().run()
        },
        isActive: editor.isActive('bold'),
        icon: faBold,
        label: 'Toggle bold'
    })

export const ToggleItalic = ({ editor }: ActionButtonProps) =>
    BasicToggleAction({
        action: () => {
            editor.chain().focus().toggleItalic().run()
        },
        isActive: editor.isActive('italic'),
        icon: faItalic,
        label: 'Toggle italic'
    })

export const ToggleStrike = ({ editor }: ActionButtonProps) =>
    BasicToggleAction({
        action: () => {
            editor.chain().focus().toggleStrike().run()
        },
        isActive: editor.isActive('strike'),
        icon: faStrikethrough,
        label: 'Toggle strike'
    })

export const ToggleBlockquote = ({ editor }: ActionButtonProps) =>
    BasicToggleAction({
        action: () => {
            editor.chain().focus().toggleBlockquote().run()
        },
        isActive: editor.isActive('blockquote'),
        icon: faBlockQuote,
        label: 'Toggle blockquote'
    })

export const ToggleCode = ({ editor }: ActionButtonProps) =>
    BasicToggleAction({
        action: () => {
            editor.chain().focus().toggleCode().run()
        },
        isActive: editor.isActive('code'),
        icon: faCode,
        label: 'Toggle code'
    })

export const SetHardBreak = ({ editor }: ActionButtonProps) =>
    BasicAction({
        action: () => {
            editor.chain().focus().setHardBreak().run()
        },
        isActive: false,
        icon: faPageBreak,
        label: 'Set hard break'
    })

export const ToggleBulletList = ({ editor }: ActionButtonProps) =>
    BasicToggleAction({
        action: () => {
            editor.chain().focus().toggleBulletList().run()
        },
        isActive: editor.isActive('bulletList'),
        icon: faListUl,
        label: 'Toggle bullet list'
    })

export const ToggleCodeBlock = ({ editor }: ActionButtonProps) =>
    BasicToggleAction({
        action: () => {
            editor.chain().focus().toggleCodeBlock().run()
        },
        isActive: editor.isActive('codeBlock'),
        icon: faCode,
        label: 'Toggle code block'
    })

export const ToggleOrderedList = ({ editor }: ActionButtonProps) =>
    BasicToggleAction({
        action: () => {
            editor.chain().focus().toggleOrderedList().run()
        },
        isActive: editor.isActive('orderedList'),
        icon: faListOl,
        label: 'Toggle ordered list'
    })

export const Headings = ({ editor }: ActionButtonProps) => {
    const headings = useMemo(() => [1, 2, 3, 4, 5, 6] as Level[], [])

    return (
        <div>
            {headings.map(level => (
                <IconButton
                    key={level}
                    onClick={() => {
                        editor.chain().focus().toggleHeading({ level }).run()
                    }}
                    variant={editor.isActive('heading', { level }) ? 'outlined' : 'text'}
                    label={`Heading ${level}`}
                    color="primary"
                    size="small"
                >
                    <Icon
                        icon={
                            level === 1
                                ? faH1
                                : level === 2
                                  ? faH2
                                  : level === 3
                                    ? faH3
                                    : level === 4
                                      ? faH4
                                      : level === 5
                                        ? faH5
                                        : faH6
                        }
                        size="1x"
                    />
                </IconButton>
            ))}
        </div>
    )
}

export const ToggleUnderline = ({ editor }: ActionButtonProps) =>
    BasicToggleAction({
        action: () => {
            editor.chain().focus().toggleUnderline().run()
        },
        isActive: editor.isActive('underline'),
        icon: faUnderline,
        label: 'Toggle underline'
    })

export const SetLink = ({ editor }: ActionButtonProps) =>
    BasicAction({
        action: () => {
            const url = window.prompt('Enter the URL')
            if (!url) return
            editor.chain().focus().setLink({ href: url }).run()
        },
        isActive: false,
        icon: faLink,
        label: 'Set link'
    })

export const SetImage = ({ editor }: ActionButtonProps) =>
    BasicAction({
        action: () => {
            const url = window.prompt('Enter the URL')
            if (!url) return
            editor.chain().focus().setImage({ src: url }).run()
        },
        isActive: false,
        icon: faImage,
        label: 'Set image'
    })
