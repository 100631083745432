export const firebaseConfig = {
    "projectId": "guidde-dev2",
    "appId": "1:311331818125:web:9b7145370569661965f0c4",
    "databaseURL": "https://guidde-dev2-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-dev2.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyDDw13KHHcP0r-3bfBHV85gcdBJJCbajKk",
    "authDomain": "dev2.guidde.com",
    "messagingSenderId": "311331818125",
    "environment": "guidde-dev2"
}

export const stripeKey =
'pk_test_51P1nMfDUvkeGRzXcYeQP9oB8YMYoQVEIRk1SSEgXCENOgPfdcb8E2YNPi0Mbdm6lv1demcxlqoPg5KHCFOT67CDs00hqy9Nybh'
export const stiggClientKey =
'client-9b091a89-cdc3-4074-a57f-0660424d7a4f:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const facebookAppId = '3155065591456766'
export const celloConfig = {
    script: 'https://assets.sandbox.cello.so/app/latest/cello.js',
    attributionScript: 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js',
    productId: 'stage-app.guidde.com'
}

export const STIGG_CLIENT_KEY =
'client-9b091a89-cdc3-4074-a57f-0660424d7a4f:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const tapfiliateAccountId =
'47910-93439a'