import { useMemo, type ComponentType, type FC } from 'react'
import { EditorContent, type AnyExtension, Editor } from '@tiptap/react'
import { useRichTextEditor } from './use-rich-text-editor'
import { ThemeProvider } from '@guidde/design-system'
import { Box, ToggleButtonGroup } from '@mui/material'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import FontFamily from '@tiptap/extension-font-family'
import {
    ToggleBold,
    ToggleBlockquote,
    ToggleItalic,
    ToggleStrike,
    ToggleBulletList,
    ToggleCodeBlock,
    ToggleOrderedList,
    ToggleUnderline,
    SetLink,
    HeadingDropdown,
    FontFamilyDropdown
} from './Actions'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'

const starterKitComponents = [
    HeadingDropdown,
    FontFamilyDropdown,
    ToggleBold,
    ToggleItalic,
    ToggleUnderline,
    ToggleStrike,
    ToggleOrderedList,
    ToggleBulletList,
    ToggleBlockquote,
    ToggleCodeBlock,
    SetLink
]

export const RichTextEditor: FC<RichTextEditorProps> = ({ content, editorExtentions }) => {
    const { components, extensions } = useMemo(() => {
        if (!editorExtentions)
            return {
                components: starterKitComponents,
                extensions: [StarterKit, Image, Underline, Link, FontFamily, TextStyle, Color]
            }

        return editorExtentions.reduce(
            (acc, { Component, extension }) => {
                acc.components.push(Component)
                acc.extensions.push(extension)
                return acc
            },
            { components: [], extensions: [] }
        )
    }, [editorExtentions])

    const editor = useRichTextEditor({
        content,
        extensions
    })

    if (!editor) return
    return (
        <ThemeProvider>
            <Box display="flex" gap={0} border="1px solid #e2e8f0" m={0} p={0}>
                <ToggleButtonGroup>
                    {components.map((Component, idx) => (
                        <Component key={idx} editor={editor} />
                    ))}
                </ToggleButtonGroup>
            </Box>
            <EditorContent editor={editor} />
        </ThemeProvider>
    )
}

RichTextEditor.defaultProps = {
    content: `    <p>This isn’t bold.</p>
        <p><strong>This is bold.</strong></p>
        <p><b>And this.</b></p>
        <p style="font-weight: bold">This as well.</p>
        <p style="font-weight: bolder">Oh, and this!</p>
        <p style="font-weight: 500">Cool, isn’t it!?</p>
        <p style="font-weight: 999">Up to font weight 999!!!</p>

          <img src="https://placehold.co/800x400" />`
}

export interface RichTextEditorProps {
    content?: string | null
    editorExtentions?: []
}
export type EditorExtentions = {
    extension: AnyExtension
    Component: ComponentType<{ editor: Editor }>
}
